import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Import React Helmet

const images = [
  '/images/gallery/hyena.jpeg',
  '/images/gallery/bateluer-eagle.jpeg',
  '/images/gallery/lion-cub-ngwenyeni.jpeg',
  '/images/gallery/lion-masorini.jpeg',
  '/images/gallery/phb-gate-sunrise.jpeg',
  '/images/gallery/sable-hide-.jpeg',
  '/images/gallery/scrub-hare.jpeg',
  '/images/gallery/steenbook-sep-2024.jpeg',
  '/images/gallery/verruaxs-eagle-owl.jpeg',
  '/images/gallery/wilddog-alone.jpeg',
];

export default function Portfolio() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // State to track if images are loading

  // Function to handle image load event
  const handleImageLoad = () => {
    setIsLoading(false); // Set loading to false once the image is loaded
  };

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const nextImage = () => {
    const currentIndex = images.indexOf(selectedImage);
    const nextIndex = (currentIndex + 1) % images.length; // loop back to the start
    setSelectedImage(images[nextIndex]);
  };

  const prevImage = () => {
    const currentIndex = images.indexOf(selectedImage);
    const prevIndex = (currentIndex - 1 + images.length) % images.length; // loop to the end
    setSelectedImage(images[prevIndex]);
  };

  return (
    <div className={`relative px-6 py-24 sm:py-32 lg:px-8 ${isLoading ? 'bg-gray-500' : 'bg-white'}`}>
      {/* Helmet for dynamic page title and favicon */}
      <Helmet>
        <title>Xipandza Mananga</title>
        <link rel="icon" href="/logo.png" type="image/png" /> {/* Favicon set to logo */}
      </Helmet>

      {/* Overlay and spinner while loading */}
      {isLoading && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex justify-center items-center z-50">
          <div className="w-16 h-16 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
        </div>
      )}

      {/* Global Overlay while Modal is Open */}
      {selectedImage && (
        <>
          {/* Gray overlay when modal is open */}
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="relative bg-white p-4 rounded-lg shadow-xl max-w-3xl max-h-screen overflow-hidden">
              <img
                src={selectedImage}
                alt="Full view"
                className="max-w-full max-h-screen object-contain rounded-lg"
              />
              {/* Close Button */}
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 text-white text-3xl font-bold hover:text-gray-400"
              >
                &times;
              </button>

              {/* Previous and Next Buttons */}
              <button
                onClick={prevImage}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white text-3xl font-bold hover:text-gray-400"
              >
                &#10094;
              </button>
              <button
                onClick={nextImage}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white text-3xl font-bold hover:text-gray-400"
              >
                &#10095;
              </button>

              {/* Close Button to return to gallery */}
              <button
                onClick={closeModal}
                className="absolute bottom-4 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-400"
              >
                Close
              </button>
            </div>
          </div>
        </>
      )}

      <div className="mx-auto max-w-7xl text-center bg-white p-8 rounded-lg shadow-lg">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          />
        </div>
        <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">My Photography Portfolio</h2>

        <div className="p-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {images.map((image, index) => (
            <div
              key={index}
              className="bg-cover bg-center h-64 cursor-pointer"
              style={{ backgroundImage: `url(${image})` }}
              onClick={() => openModal(image)}
            >
              <img
                src={image}
                alt={`Gallery image ${index}`}
                className="w-full h-full object-cover opacity-0" // Hidden by default until loaded
                onLoad={handleImageLoad} // Trigger the loading complete event
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
